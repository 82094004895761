#page-header {
  margin-bottom: $page-header-margin-bottom;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: $white;
  box-shadow: 0 0 1rem lighten($gray-light, 30%);

  @include media-breakpoint-down(xs) {
    margin-bottom: 0;
  }

  .row {

    align-items: center;

    .brand-logo {
      @extend .col;

      img {
        width: 250px;
        max-width: 100%;
      }
    }

    .avatar {
      @extend .col-auto;

      display: flex;
      align-items: center;

      color: $gray-light;

      .dropdown {

        button {
          display: inline-block;
          margin-left: 1rem;
          width: 60px;
          height: 60px;
          cursor: default;

          border-radius: $border-radius;

          background: url("../images/avatar.png") center 5px no-repeat;

          &:not(.nohover) {
            cursor: pointer;
            &:hover,
            &:focus {
              background-color: $brand-primary;
              background-position: center -45px;
            }
          }
        }

        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }
	
	.notif {
      @extend .col-auto;

      display: flex;
      align-items: center;

      color: $gray-light;

      .dropdown {

        button {
          display: inline-block;
          margin-left: 1rem;
          width: 40px;
          height: 40px;
          cursor: default;

          border-radius: $border-radius;

          background: url("../images/bell_notif.jpg") center 5px no-repeat;

          &:not(.nohover) {
            cursor: pointer;
            &:hover,
            &:focus {
             transform: scale(1.2);
            }
          }
        }

        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }
	
	
	
  }
}

.le-saviez-vous {
  margin-top: -($page-header-margin-bottom - 0.4rem);

  @include media-breakpoint-down(xs) {
    margin-top: 0;
    display: none;
  }

  .container {
    overflow: hidden;
  }

  .row {
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .image-lsv {
      display: flex;
      align-items: center;
      align-self: stretch;

      min-height: 5rem;
      padding-left: 250px;
      padding-right: 0.5rem;
      background: url('../images/couple.png') no-repeat left top;

      @include media-breakpoint-down(lg) {
        background-position: -80px top;
        padding-left: 160px;
      }

      @include media-breakpoint-down(md) {
        img {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

}

.banner {
  background: $green-oralia;
  color: #fff;
  width: 100%;
  padding: $banner-padding-y 0 ($banner-padding-y + $chevauchement-banner) 0;

  .row {
    position: relative;
    align-items: center;
  }

  h1 {
    font-size: 1.8rem;
    margin: 0;

    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 1.6rem;
    }
  }
}

.change-account {
  margin-left: 1.5rem;

  @include media-breakpoint-only(md) {
    margin-left: 0;
    text-align: right;
  }

  @include media-breakpoint-down(sm) {
    margin: 2rem 0 -1rem 0;
    text-align: center;
  }

  .btn-change-account {
    display: inline-block;
    width: 12.5rem;
    padding-left: 50px;
    border: #fff solid 1px;
    border-radius: $border-radius;

    background: url('../images/home.png') no-repeat 10px center;

    box-shadow: 0 0 0.5rem -0.2rem;

    .txt-btn {
      display: inline-block;
      padding: 0.5rem;
      text-align: center;
      color: $brand-primary;
      background-color: #fff;
      font-size: 0.8rem;
      line-height: 1.3;
      text-transform: uppercase;
      border-radius: 0 $border-radius $border-radius 0;
      border-left: 1px solid rgba(#fff, 0.5);
      font-weight: 400;
    }

    &:hover,
    &:focus {
      box-shadow: none;
      .txt-btn {
        color: #fff;
        background: none;
        font-weight: 500;
      }
    }
  }
}