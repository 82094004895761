.location .banner {
    background: $orange-oralia
}

.location h1 {
    color: $orange-oralia
}

.location h1 .little-border {
    border-bottom-color: $orange-oralia!important
}

.location #nav-primary .nav-link.active {
    color: $orange-oralia
}

.location #nav-primary .dropdown-item.active,
.location #nav-primary .dropdown-item:active,
.location .collapse-box.personalized .collapse-box-header {
    background: $orange-oralia
}

.location .collapse-box.personalized .collapse-box-content {
    border-color: $orange-oralia
}

.location .collapse-box.personalized .collapse-box .collapse-box-header:after {
    color: $orange-oralia
}

.location .input-group-addon {
    color: #fff;
    background: $orange-oralia
}

.location .btn-personalized {
    color: #fff;
    background-color: $orange-oralia;
    border-color: $orange-oralia
}

.location .btn-personalized:hover {
    color: #fff;
    background-color: #d17b0c;
    border-color: #c7750c
}

.location .btn-personalized.focus,
.location .btn-personalized:focus {
    box-shadow: 0 0 0 2px rgba(242, 149, 30, .5)
}

.location .btn-personalized.disabled,
.location .btn-personalized:disabled {
    background-color: $orange-oralia;
    border-color: $orange-oralia
}

.location .btn-personalized.active,
.location .btn-personalized:active,
.show>.location .btn-personalized.dropdown-toggle {
    color: #fff;
    background-color: #d17b0c;
    background-image: none;
    border-color: #c7750c
}

.location .btn-personalized-o {
    color: $orange-oralia;
    background-image: none;
    background-color: transparent;
    border-color: $orange-oralia
}

.location .btn-personalized-o:hover {
    color: #fff;
    background-color: $orange-oralia;
    border-color: $orange-oralia
}

.location .btn-personalized-o.focus,
.location .btn-personalized-o:focus {
    box-shadow: 0 0 0 2px rgba(242, 149, 30, .5)
}

.location .btn-personalized-o.disabled,
.location .btn-personalized-o:disabled {
    color: $orange-oralia;
    background-color: transparent
}

.location .btn-personalized-o.active,
.location .btn-personalized-o:active,
.show>.location .btn-personalized-o.dropdown-toggle {
    color: #fff;
    background-color: $orange-oralia;
    border-color: $orange-oralia
}

.location .card.card-voisinage .card-block .card-title {
    color: $orange-oralia
}

.location .forum .forum-header,
.location .style-figure {
    background-color: $orange-oralia
}

.location .actualite-publication .message-author,
.location .btn-change-account .txt-btn,
.location .color-perzonalized {
    color: $orange-oralia
}

.location .thead-personalized {
    background-color: $orange-oralia;
    color: #fff
}

.location .bg-perzonalized {
    background-color: $orange-oralia!important
}

.location .banner .current-account {
    background: url(../images/location-logo.jpg) no-repeat 0
}

.location .forum .forum-message.current .message-text {
    background-color: #cdedf9
}

.location .forum .forum-message.current .message-text:before {
    border-color: #cdedf9 transparent transparent
}