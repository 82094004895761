.forum {
  border: 1px solid $gray-lighter;
  .forum-header {
    background-color: $brand-primary;
    color: #fff;
    padding: .8rem 1rem;
    margin: 0;
  }

  .forum-content {
    padding: $grid-gutter-width-base/2;

    .date-sep {
      position: relative;
      text-align: center;
      color: #cdcdcd;
      margin: 2rem 2rem;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        content: '';
        border-bottom: 1px solid #cdcdcd;
      }
      &::before {
        left: 0;
        right: 50%;
        margin-right: 7rem;
      }
      &::after {
        right: 0;
        left: 50%;
        margin-left: 7rem;
      }

      &:first-child {
        margin-top: 1rem;
      }
    }

    .forum-message {
      margin-bottom: 2rem;
      .message-profile {
        width: 3rem;
        margin-right: -.9rem;
        @include media-breakpoint-up(sm) {
          width: 4rem;
        }
      }
      .message-author {
        font-size: .9em;
        font-weight: bold;
        margin-bottom: .3rem;
      }
      .message-text {
        position: relative;
        padding: .5rem 1rem .9rem 1rem;
        background: #e6e6e6;
        border-radius: 0 .5rem .5rem .5rem;
        font-size: .95rem;

        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;

          content: '';

          margin-left: -.5rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 .5rem .5rem 0;
          border-color: transparent #e6e6e6 transparent transparent;
        }

        time {
          position: absolute;
          bottom: .1rem;
          right: .5rem;
          font-size: .75rem;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      &.current {
        .message-profile {
          margin-left: -.9rem;
          margin-right: 0;
        }

        .message-author {
          text-align: right;
        }

        .message-text {
          border-radius: .5rem 0 .5rem .5rem;

          &::before {
            left: auto;
            right: 0;
            margin-left: 0;
            margin-right: -.5rem;
            border-width: .5rem .5rem 0 0;
          }
        }
      }
    }
  }

  .forum-footer {
    padding: $grid-gutter-width-base/2;
    border-top: #f3f3f3 solid 1px;
  }
}