.propriete {
    
    .banner {
        background: $green-light-oralia
    }    
    
    h1 {
        color: $green-light-oralia
    }    
    
    .page-content h1 .little-border {
        border-color: $green-light-oralia !important
    }
    
    .page-content h1 .little-border.color-transaction {
        border-color: $rose-oralia-transaction !important
    }

    .color-location {    
        color: $orange-oralia !important
    }

    .bg-location {
        background-color: $orange-oralia;
    }

    .card-outline-location {
        border-color: $orange-oralia;
    }

    h1 .little-border.orange {
        border-bottom-color: $orange-oralia!important
    }
    
    #nav-primary .nav-link.active {
        color: $green-light-oralia
    }

    #nav-primary .dropdown-item.active,
    #nav-primary .dropdown-item:active,
    .collapse-box.personalized .collapse-box-header {
        background: $green-light-oralia
    }

    .collapse-box.personalized .collapse-box-content {
        border-color: $green-light-oralia
    }

    .collapse-box.personalized .collapse-box .collapse-box-header:after {
        color: $green-light-oralia
    }

    .input-group-addon {
        color: #fff;
        background: $green-light-oralia
    }

    .btn-personalized {
        color: #fff;
        background-color: $green-light-oralia;
        border-color: $green-light-oralia
    }

    .btn-personalized:hover {
        color: #fff;
        background-color: #d17b0c;
        border-color: #c7750c
    }

    .btn-personalized.focus,
    .btn-personalized:focus {
        box-shadow: 0 0 0 2px rgba(242, 149, 30, .5)
    }

    .btn-personalized.disabled,
     .btn-personalized:disabled {
        background-color: $green-light-oralia;
        border-color: $green-light-oralia
    }

    .btn-personalized-o {
        color: $green-light-oralia;
        background-image: none;
        background-color: transparent;
        border-color: $green-light-oralia
    }

    .btn-personalized-o:hover {
        color: #fff;
        background-color: $green-light-oralia;
        border-color: $green-light-oralia
    }

    .btn-personalized-o.focus,
    .btn-personalized-o:focus {
        box-shadow: 0 0 0 2px rgba(242, 149, 30, .5)
    }

    .btn-personalized-o.disabled,
    .btn-personalized-o:disabled {
        color: $green-light-oralia;
        background-color: transparent
    }

    .card.card-voisinage .card-block .card-title {
        color: $green-light-oralia
    }

    .forum .forum-header,
    .style-figure {
        background-color: $green-light-oralia
    }

    .actualite-publication .message-author,
    .propriete .btn-change-account .txt-btn,
    .color-perzonalized {
        color: $green-light-oralia
    }

    .thead-personalized {
        background-color: $green-light-oralia;
        color: #fff
    } 

    .font-10{
        font-size: 12px;
    }       
    
    .table td{
        border-top: none;
    }

    .bg-perzonalized {
        background-color: $green-light-oralia!important
    }

    .banner .current-account {
        background: url(../images/gestion-logo.jpg) no-repeat 0
    }

    .forum .forum-message.current .message-text {
        background-color: #cdedf9
    }

    .forum .forum-message.current .message-text:before {
        border-color: #cdedf9 transparent transparent
    }

    &.synthese .collapse-box-content .table thead:not(.thead-normal) {
        background-color: #fff;
        color: #000;
    }
    .table thead th {
        border-bottom: none;
    }
    
    &.synthese .collapse-box .collapse-box-header {
        background-color: #bbb;
    }
    
    &.synthese .collapse-box .collapse-box-content {
        border: 1px solid #bbb;    
    }
    
    &.synthese table.tab-foot {
        background-color: $green-oralia;    
        color: #fff;
        border-radius: 5px;
    }
    
    .box-info {
        border-radius: 5px;
        border: 1px solid #bbb;
        background-color: $green-light-oralia;
        padding: 1rem;
        color: #fff;
        p{
            margin: 0;
        }
    }   
} 

.propriete.synthese .table tbody tr {
    border-top: 1px solid #eceeef;
}    

.propriete .btn-personalized.active,
.propriete .btn-personalized:active,
.show>.propriete .btn-personalized.dropdown-toggle {
    color: #fff;
    background-color: #d17b0c;
    background-image: none;
    border-color: #c7750c
}


.propriete .btn-personalized-o.active,
.propriete .btn-personalized-o:active,
.show>.propriete .btn-personalized-o.dropdown-toggle {
    color: #fff;
    background-color: $green-light-oralia;
    border-color: $green-light-oralia
}

.releve-gestion .link-tr {
    cursor: pointer;
}