.content-inner {
  div > ul.custom-list {
    list-style: none;
    padding: 0;
    & > li {
      position: relative;
      padding-left: 1rem;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '>';
        color: $gray-light;
      }

      & > ul {
        list-style: disc;
      }
    }
  }
}