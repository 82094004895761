html {
  height: 100%;

  font-size: 14px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.background-grey {
    background: $gray-lightest;
  }
  &.background-image {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -10;
      opacity: 0.35;
      background: url("../images/oralia-background.jpg") no-repeat center top fixed;
      background-size: cover;
    }
  }
}

.page-container {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.page-content {
  @include make-row();

  margin: -$chevauchement-banner 0 $grid-gutter-width-base 0 !important;

  @include media-breakpoint-down(xs) {
    margin-bottom: 0 !important;
  }

  .content {
    background: #fff;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    border: #dfdfdf 1px solid;
    border-radius: $border-radius;

    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }
}

.responsive-notice {
  width: 1.5rem;
  margin: 0 0.1rem 0 0.2rem;
}

.image-upload {

  max-width: 150px;

  label {
    position: relative;
    display: block;
    margin: 0;

    cursor: pointer;

    img {
      @extend .rounded-circle;
      @extend .img-fluid;

      border: solid 3px $brand-primary;
    }

    &:hover,
    &:focus {
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;

        align-items: center;
        justify-content: center;

        content: '\f030';
        font-family: FontAwesome;

        color: $white;
        font-size: 3rem;

        background-color: rgba($brand-primary, 0.4);

        border-radius: 50%;
      }
    }
  }
  input {
    display: none;
  }
}

.fa-rounded-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brand-primary;
  @extend .rounded-circle;
  width: 1.8em;
  height: 1.8em;
  color: #fff;
  font-size: 2.5rem;
}

.c-red {
  color: $red;
}

.c-green {
  color: $green;
}

.c-orange {
  color: #ED662F;
}

.c-light-green {
  color: #94BF20;
}

.c-brand-primary {
  color: $brand-primary;
}

.c-warning {
  color: $brand-warning;
}

.font-9 {
  font-size: .9em;
}

@for $i from 1 through 10 {
  .min-w-#{$i*2} {
    min-width: #{$i*2}rem;
  }

  .max-w-#{$i*2} {
    max-width: #{$i*2}rem;
  }
}

.contact-box {
  background-color: #F5F5F5;
  padding: $grid-gutter-width-base/2;

  p {
    margin-bottom: .2rem;
  }
}

@for $i from 1 through 10 {
  $background-position: ($i - 1) * $voisinage-picto-size * -1;
  .ico-voisinage-#{$i} {
    @extend .ico-voisinage;
    background-position: center $background-position;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.text-big {
  font-size: 2em !important;
}

.bg-transaction {
  background-color: $rose-oralia-transaction;
}

.color-transaction {
  color: $rose-oralia-transaction;
}

#details-signalement {
  display: none;
}

.subtitle {
  font-size: .9em;
  margin-bottom: 1rem;
}

.text-no-transform {
  text-transform: none !important;
}

.style-figure {
  display: inline-flex;
  font-size: 2.3em;
  background-color: $brand-primary;
  color: #fff;
  border-radius: 50%;
  height: .9em;
  width: .9em;
  line-height: 1;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-family: 'Indie Flower', cursive;
}

.link-unstyled {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

.border-warning {
  border-color: $brand-warning !important;
}

.fixe-height {
  height: 140px;
}

.border-grey {
  border: 1px solid #cccccc;
}