.collapse-box {
  border-radius: $border-radius;
  margin-bottom: 1rem;

  .collapse-box-header {
    position: relative;
    display: block;
    width: 100%;
    border-radius: $border-radius $border-radius 0 0;
    background: $brand-primary;
    color: #fff;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none !important;
    padding-right: 2rem;

    &::after {
      position: absolute;
      display: flex;
      align-items: center;
      font-family: FontAwesome;
      content: '\f13a';
      top: 0;
      right: 0.5rem;
      bottom: 0;
      font-size: 1.5rem;
    }

    &.collapsed {
      border-radius: $border-radius;

      &::after {
        content: '\f139';
      }
    }

    &.toggler-left {
      padding-left: 3.5rem;
      padding-right: 1rem;
      &::after {
        left: 1.5rem;
        right: auto;
      }
    }
    &.no-toggler {
      &::after {
        display: none !important;
      }
    }
  }
  .collapse-box-content {
    border: 1px solid $brand-primary;
    border-radius: 0 0 $border-radius $border-radius;
    .list-group-item {
      padding-left: 1rem;
      padding-right: 1rem;
      border-left: none;
      border-right: none;
      border-bottom: none;
      &:first-child {
        border-top: none;
        border-radius: 0;
      }
    }
    p {
      padding-left: $grid-gutter-width-base/2;
      padding-right: $grid-gutter-width-base/2;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .collapse-box {
    margin-bottom: 0;

    .collapse-box-header {
      background: none !important;
      color: #000;
      border-radius: 0;
      border-bottom: 1px $gray-lighter solid;
      padding-left: 2rem;
      text-transform: none;

      &::after {
        content: '\f078';
        font-size: 1.2rem;
        top: .1rem;
        color: $brand-primary;
      }

      &.collapsed {
        &::after {
          content: '\f054';
        }
      }

      &.toggler-left {
        padding-left: 4.2rem;
        &::after {
          left: 2.5rem;
        }
      }
    }

    .collapse-box-content {
      border: none;
      background: #e6e6e6;
      box-shadow: inset 0 14px 20px -20px rgba(105, 105, 105, 1);
      .list-group-item {
        border-top-color: #fff;
        background: transparent;
        padding-left: 5rem;
        @include media-breakpoint-down(sm) {
          padding-left: $grid-gutter-width-base/2;
        }
      }
      p {
        padding-left: 5rem;
        @include media-breakpoint-down(sm) {
          padding-left: $grid-gutter-width-base/2;
        }
      }
    }

    &:last-child {
      .collapse-box-header {
        border-bottom: none;
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }
}

.collapse-box-secondary {
  .collapse-box-header {
    background: #B4B4B4;
  }
  .collapse-box-content {
    border-color: #a7a7a7;
  }
}

.accordion {
  .collapse-box {
    margin-bottom: 0;
    .collapse-box-header {
      border-radius: 0;
    }
    &:not(:last-child) {
      .collapse-box-content {
        border-radius: 0;
      }
    }
    &:first-child {
      .collapse-box-header {
        border-radius: $border-radius $border-radius 0 0;
      }
    }
    &:last-child {
      .collapse-box-header.collapsed {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }
}