.accounts-container {
  @include make-row();

  margin-bottom: -($grid-gutter-width-base/2);

  justify-content: center;

  .account-item {
    display: block;

    margin-bottom: ($grid-gutter-width-base/2);

    @include make-col-ready();

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;

      .account-box {
        box-shadow: 0 0 0.5rem 0.1rem lighten($gray-light, 40%);
      }
    }

    .account-box {
      display: flex;
      align-items: center;

      border: lighten($gray-light, 30%) solid 1px;
      border-radius: $border-radius;

      .illustration {
        min-height: 90px;
        min-width: 90px;
        align-self: stretch;
        margin: 0;
      }

      &.copropriete {
        .illustration {
          background: $blue-oralia url("../images/copropriete-logo.jpg") center no-repeat;
        }
      }

      &.gestion {
        .illustration {
          background: $green-light-oralia url("../images/gestion-logo.jpg") center no-repeat;
        }
      }

      &.location {
        .illustration {
          background: $color-location url("../images/location-logo.jpg") center no-repeat;
        }
      }

      @include media-breakpoint-only(xs) {
        .illustration {
          min-height: 60px;
          min-width: 60px;
          background-size: 50px !important;
        }
      }

      .details {
        padding: 0.5rem 1rem;

        text-transform: uppercase;

        h3 {
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 0.5rem;

          .address::before {
            content: ' - ';
          }
        }

        p {
          margin-bottom: 0;
          color: $gray;
        }
      }
    }
  }
}

.accounts-square {

  margin-bottom: -$grid-gutter-width-base;
  .account-item {

    margin-bottom: $grid-gutter-width-base;

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    .account-box {
      flex-direction: column;
      .illustration {
        height: 8rem;
        width: 100%;
      }

      .details {
        text-align: center;

        padding-top: 1rem;
        padding-bottom: 1rem;

        h3 {
          margin-bottom: 1rem;

          .address {
            display: block;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}

.select-account {
  .content-header {
    @include make-col-ready();

    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    margin-bottom: 1rem;

    h2 {
      text-align: left;
      margin-bottom: 1.5rem;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
}