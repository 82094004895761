
.table {
  thead {
    th {
      border-top: none;
    }
  }

  .fa-check {
    color: $green;
  }
  .fa-times {
    color: $red;
  }
}

.table-personalized {
  thead,
  tfoot {
    background-color: $brand-primary;
    color: white;
  }
}

.table-responsive {
  *:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: .85em;

    th, td {
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }
}


.location .collapse-box-content {
  .table {
    font-size: 0.75em;
  }
}

.collapse-box-content {
  .table {
    font-size: .9em;
    thead:not(.thead-normal) {
      background-color: #acacac;
      color: #fff;

      th {
        vertical-align: top;
        small {
          font-size: .79em;
        }
      }
    }
    tfoot {
      background-color: #acacac;
      color: #fff;
      font-weight: 500;
    }
  }
}

.table {
  .heading-1 {
    font-size: 1.05em;
    font-weight: 700;
  }
  .heading-2 {
    font-size: 1em;
    font-weight: 500;
    background-color: #e6e6e6;
  }
}

.table-budget {
  font-size: .9em;
  border: 1px solid #e6e6e6;
  border-top: none;
  border-radius: $border-radius;

  th, td {
    padding: .5rem .5rem;


    &:last-child {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &:first-child {
      @include media-breakpoint-down(md) {
        display: table-cell !important;
      }
    }
  }
  .heading-1 {
    font-weight: normal;
    font-size: 1.1em;
    @include media-breakpoint-down(sm) {
      font-size: 1em;
    }
  }
  .heading-2 {
    background-color: #e6e6e6;
    td {
      font-weight: bold;
      &:first-child {
        font-weight: 500;
        @include media-breakpoint-up(md) {
          padding-left: 1rem;
        }
      }
    }
  }
  .heading-3 {
    td:first-child {
      font-weight: bold;
      @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
      }
    }
  }
  .heading-4 {
    @include media-breakpoint-down(sm) {
      display: none;
    }
    td {
      &:first-child {
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
          padding-left: 2.5rem;
        }
      }
    }
  }
  .heading-4-mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }
    td:first-child {
      text-transform: uppercase;
    }
  }
}

.fixed-head-detail{
  width: 100%;
  th, td {
    text-align: left;
  }
  
  td:nth-child(1), th:nth-child(1) { width: 50%; }
  td:nth-child(2), th:nth-child(2) { width: 40%; }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 300px;
  }
}


.fixed-head{
  width: 100%;
  th, td {
    text-align: left;
  }
  
  td:nth-child(1), th:nth-child(1) { width: 20%; }
  td:nth-child(2), th:nth-child(2) { width: 50%; }
  td:nth-child(3), th:nth-child(3) { width: 15%; }
  td:nth-child(4), th:nth-child(4) { width: 15%; }
  td:nth-child(5), th:nth-child(5) { width: 15%;  
    @include media-breakpoint-down(sm) {
      width: 10%;
    }
  }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 300px;
  }
}

.fixed-head-loyer{
  width: 100%;
  th, td {
    text-align: left;
  }
  
  td:nth-child(1), th:nth-child(1) { width: 400px; 

  }
  td:nth-child(2), th:nth-child(2) { width: 300px; }
  td:nth-child(3), th:nth-child(3) { width: 100px; }
  td:nth-child(4), th:nth-child(4) { width: 90px; }
  td:nth-child(5), th:nth-child(5) { width: 90px; }
  td:nth-child(6), th:nth-child(6) { width: 90px; }
  td:nth-child(7), th:nth-child(7) { width: 90px; }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 300px;
  }
}

.fixed-head-etat{
  width: 100%;
  th, td {
    text-align: left;
  }
  
  td:nth-child(1), th:nth-child(1) { width: 40%; }
  td:nth-child(2), th:nth-child(2) { width: 12%; }
  td:nth-child(3), th:nth-child(3) { width: 12%; }
  td:nth-child(4), th:nth-child(4) { width: 12%; }
  td:nth-child(5), th:nth-child(5) { width: 12%; }
  td:nth-child(6), th:nth-child(6) { width: 12%; }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 300px;
  }
}

.fixed-head-budget{
  width: 100%;
  th, td {
    text-align: left;
  }
  
  td:nth-child(1), th:nth-child(1) { width: 29%; }
  td:nth-child(2), th:nth-child(2) { width: 15%; }
  td:nth-child(3), th:nth-child(3) { width: 15%; }
  td:nth-child(4), th:nth-child(4) { width: 15%; }
  td:nth-child(5), th:nth-child(5) { width: 15%; }
  td:nth-child(6), th:nth-child(6) { width: 15%; }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 300px;
  }
  tfoot, thead {
    display: block;
    overflow: auto;
    width: 100%;
  }
}

.fixed-head-releve{
  width: 100%;
  th, td {
    text-align: left;
  }
  
  td:nth-child(1), th:nth-child(1) { width: 60%; }
  td:nth-child(2), th:nth-child(2) { width: 1%; }
  td:nth-child(3), th:nth-child(3) { width: 1%; }
  td:nth-child(4), th:nth-child(4) { width: 20%; }

  thead {
    tr {
      display: block;
      position: relative;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 200px;
  }
}