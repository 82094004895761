.copropriete {
  .banner {
    background: $color-copropriete;

    .current-account {
      background: url('../images/copropriete-logo.jpg') no-repeat left center;
    }
  }
  h1 {
    color: $color-copropriete;

    .little-border {
      border-bottom-color: $color-copropriete !important;
    }
  }
  #nav-primary {
    .nav-link.active {
      color: $color-copropriete;
    }

    .dropdown-item {
      &.active,
      &:active {
        background: $color-copropriete;
      }
    }
  }

  .collapse-box.personalized {
    .collapse-box-header {
      background: $color-copropriete;
    }

    .collapse-box-content {
      border-color: $color-copropriete;
    }

    .collapse-box {
      .collapse-box-header {
        &::after {
          color: $color-copropriete;
        }
      }
    }
  }

  .input-group-addon {
    color: #fff;
    background: $color-copropriete;
  }
  .btn-personalized {
    @include button-variant($white, $blue-oralia, $blue-oralia);
  }
  .btn-personalized-o {
    @include button-outline-variant($blue-oralia);
  }
  .card.card-voisinage {
    .card-block {
      .card-title {
        color: $color-copropriete;
      }
    }
  }

  .forum {
    .forum-header {
      background-color: $color-copropriete;
    }

    .forum-message.current {
      .message-text {
        background-color: #cdedf9;

        &::before {
          border-color: #cdedf9 transparent transparent transparent;
        }
      }
    }
  }

  .style-figure {
    background-color: $color-copropriete;
  }

  .actualite-publication {
    .message-author {
      color: $color-copropriete;
    }
  }
  
  .color-perzonalized {
    color: $color-copropriete;
  }

  .btn-change-account {
    .txt-btn {
      color: $color-copropriete;
    }
  }

  .thead-personalized {
    background-color: $color-copropriete;
    color: #fff;
  }
}