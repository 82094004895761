.ico-voisinage {
  background: url("../images/pictos-voisinage.png") center top / $voisinage-picto-size no-repeat;
  width: $voisinage-picto-size;
  height: $voisinage-picto-size;
  margin-right: .8rem;
}

@for $i from 1 through 10 {
  $background-position: ($i - 1) * $voisinage-picto-size * -1;
  .ico-voisinage-#{$i} {
    @extend .ico-voisinage;
    background-position: center $background-position;
  }
}

.signaler-picto,
.signaler-picto-1,
.signaler-picto-2,
.signaler-picto-3,
.signaler-picto-4,
.signaler-picto-5,
.signaler-picto-6,
.signaler-picto-7,
.signaler-picto-8,
.signaler-picto-9,
.signaler-picto-10,
.signaler-picto-11 {
    background: url(../images/pictos-signaler.png) 0 0/200% no-repeat;
    max-width: 100%;
    padding-bottom: 100%;
    height: 0;
    cursor: pointer;
}

.signaler-picto-1 {
    background-position: 0 30%;
}

.signaler-picto-1.active,
.signaler-picto-1:hover {
    background-position: 100% 30%;
}

.signaler-picto-2 {
    background-position: 0 40%;
}

.signaler-picto-2.active,
.signaler-picto-2:hover {
    background-position: 100% 40%
}

.signaler-picto-3 {
    background-position: 0 50%
}

.signaler-picto-3.active,
.signaler-picto-3:hover {
    background-position: 100% 50%
}

.signaler-picto-4 {
    background-position: 0 60%
}

.signaler-picto-4.active,
.signaler-picto-4:hover {
    background-position: 100% 60%
}

.signaler-picto-5 {
    background-position: 0 70%
}

.signaler-picto-5.active,
.signaler-picto-5:hover {
    background-position: 100% 70%
}

.signaler-picto-6 {
    background-position: 0 80%
}

.signaler-picto-6.active,
.signaler-picto-6:hover {
    background-position: 100% 80%
}

.signaler-picto-7 {
    background-position: 0 90%
}

.signaler-picto-7.active,
.signaler-picto-7:hover {
    background-position: 100% 90%
}

.signaler-picto-8 {
    background-position: 0 10%
}

.signaler-picto-8.active,
.signaler-picto-8:hover {
    background-position: 100% 10%
}

.signaler-picto-9 {
    background-position: 0 20.015%
}

.signaler-picto-9.active,
.signaler-picto-9:hover {
    background-position: 100% 20.015%
}

.signaler-picto-10 {
    background-position: 0 0%
}

.signaler-picto-10.active,
.signaler-picto-10:hover {
    background-position: 100% 0%
}

.signaler-picto-11 {
    background-position:  0 100%;
}

.signaler-picto-11.active,
.signaler-picto-11:hover {
    background-position: 100% 100%;
}

.question-picto-12,
.question-picto-13,
.question-picto-14 {
    background: url(../images/pictos-question.png) 0 0/200% no-repeat;
    max-width: 100%;
    padding-bottom: 100%;
    height: 0;
    cursor: pointer
}

.question-picto-12 {
    background-position: 0 0%;
}

.question-picto-12.active,
.question-picto-12:hover {
    background-position: 100% 0%;
}


.question-picto-13 {
    background-position: 0 50.1%;
}

.question-picto-13.active,
.question-picto-13:hover {
    background-position: 100% 50.1%;
}


.question-picto-14 {
    background-position: 0 100%;
}

.question-picto-14.active,
.question-picto-14:hover {
    background-position: 100% 100%
}

.icone-info {
    background: url(../images/icones/infos-pratiques.svg);
    background-repeat: no-repeat;
    background-size: 35px 35px;
    width: 35px;
    height: 35px;
}

.btn-infos-immeuble:hover .icon-white{
  -webkit-filter: grayscale(100%) brightness(5);
  filter: grayscale(100%) brightness(5);
}

.icon-forum {
    background: url(../images/icones/bulle-forum-copro.svg);
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
}
.icon-forum-s {
    background: url(../images/icones/bulle-forum-copro.svg);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
}

.icon-envelope {
    background: url(../images/icones/Oralia-Pictos-27.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

a:hover .icon-envelope {
    background: url(../images/icones/Oralia-Pictos-28.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-picture {
    background: url(../images/icones/image-fil-dactu-copro.svg);
    background-repeat: no-repeat;
    width: 22px;
    height: 19px;
    margin-bottom: -4px;  
}

.icon-avion {
    background: url(../images/icones/avion-publier-fildactu-copro.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    margin-bottom: -3px;   
}

.icon-heart {
    background: url(../images/icones/Oralia-Pictos-29.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-bottom: -4px;    
    &:hover {
        background: url(../images/icones/Oralia-Pictos-30.svg);
    }  
}

.icon-com {
    background: url(../images/icones/Oralia-Pictos-31.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;    
    margin-bottom: -4px;    
    &:hover {
        background: url(../images/icones/Oralia-Pictos-32.svg);
    }  
}

.icon-intervention-info {
    background: url(../images/icones/interventions1.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-intervention-avion-copro {
    background: url(../images/icones/interventions-copro.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-intervention-avion-loc {
    background: url(../images/icones/interventions-loc.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-intervention-avion-proprio {
    background: url(../images/icones/interventions-proprio.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-intervention-envelop {
    background: url(../images/icones/argumentaire-commercial4.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-clock {
    background: url(../images/icones/interventions2.svg);
    background-repeat: no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;    
}

.icon-phone {
    background: url(../images/icones/telephone-blanc-contacter-profil.svg);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;    
}

.icon-message {
    background: url(../images/icones/enveloppe-blanche-profil.svg);
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;    
}

.icon-plus {
    background: url(../images/icones/plus-blanc-mon-profil.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-bottom: -4px;
}

.icon-delete {
    background: url(../images/icones/corbeille-blanche-profil.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 22px;
    background-size: 25px 24px;   
}

.icon-info-v {
    background: url(../images/icones/Oralia-Pictos-38.svg);
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-size: 30px 25px;
    margin-bottom: -5px;  
}

.icon-info-o {
    background: url(../images/icones/Oralia-Pictos-37.svg);
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-size: 30px 25px;
    margin-bottom: -5px;  
}

.icon-lock {
    background: url(../images/icones/argumentaire-commercial2.svg);
    background-repeat: no-repeat;
    width: 70px;
    height: 70px; 
}

.icon-planer {
    background: url(../images/icones/argumentaire-commercial3.svg);
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
}

.icon-price {
    background: url(../images/icones/argumentaire-commercial1.svg);
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
}

.icon-process {
    background: url(../images/icones/argumentaire-commercial5.svg);
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
}

.icon-euro {
    background: url(../images/icones/paiement-en-ligne-service.svg);
    background-repeat: no-repeat;
    width: 28px;
    height: 32px;
    margin-top: -9px;
    background-size: 40px 40px;
    margin-left: -10px;
}

.icon-menage {
    background: url(../images/icones/nos-services.svg);
    background-repeat: no-repeat;
    width: 130px;
    height: 130px;
}

.icon-artisanat {
    background: url(../images/icones/nos-services2.svg);
    background-repeat: no-repeat;
    width: 130px;
    height: 130px;
}