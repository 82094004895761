.form-control:focus::placeholder {
  color: transparent;
}

.custom-select {
  &:focus,
  &:active {
    border-color: $brand-primary;
  }
}

.custom-checkbox-lg {
  font-size: 1.15em;
  padding-left: 1.5em;
  .custom-control-indicator {
    top: .25em;
    width: 1em;
    height: 1em;
  }
}

.search-form {
  position: relative;

  label {
    position: absolute;
    left: 0.7rem;
    top: 0.4rem;
    color: $gray-light;
  }
  #search-input {
    padding-left: 2rem;
  }
}

.input-group-addon {
  color: #fff;
  background: $brand-primary;
}