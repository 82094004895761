#page-footer {
  margin-top: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: $brand-primary;
  color: $white;

  a {
    color: white;
  }
}