#nav-primary {
  position: relative;

  .menu-toggle {
    display: none;

    width: 100%;

    text-align: left;
    text-transform: uppercase;

    .fa {
      margin-right: 0.5rem;
    }
  }

  .nav {

    .nav-item {

      &:hover,
      &:focus {
        background-color: $gray-lighter;
      }

      &.dropdown {
        &:hover,
        &:focus {
          .dropdown-menu {
            display: block;
            width: 100%;
          }
        }

        &.show {
          .nav-link {
            background: #e6e6e6;
          }
        }

        .dropdown-menu {
          margin: 0;

          .dropdown-item {
            display: inline-block;
          }
        }
      }

      .nav-link {
        color: #000;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-transform: uppercase;
        border-radius: 0;

        &.active,
        a:active {
          font-weight: bold;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #nav-primary {

    position: absolute;
    top: $nav-mobile-margin-top;
    left: $nav-mobile-margin-side;
    right: $nav-mobile-margin-side + $nav-mobile-btn-right-width;

    z-index: 10;

    padding-left: 0;
    padding-right: 0;

    background-color: #fff;

    .nav {
      display: none;
      flex-direction: column;

      margin-top: -$nav-mobile-border-size;

      background: #fff;

      border: $nav-mobile-border;
      border-top: none;

      border-radius: 0 0 $border-radius $border-radius;

      margin-right: -$nav-mobile-btn-right-width;

      .nav-item {
        text-align: left;

        &.dropdown {
          &:hover,
          &:focus {
            .dropdown-menu {
              display: none;
            }
          }

          .nav-link {
            position: relative;
            padding-right: 2.5rem;

            &::after {
              position: absolute;
              top: 0;
              bottom: 0;
              right: .8rem;

              display: flex;
              align-items: center;

              content: '\f054';
              font-family: FontAwesome;
              font-size: 1rem;
            }
          }

          .dropdown-menu {
            position: relative;
            top:0;
            left:0;
            border-radius: 0;
            border: none;
            width: 100%;
          }

          &.show,
          &:active {
            .dropdown-menu {
              display: block !important;
            }

            .nav-link::after {
              content: '\f078'
            }
          }
        }

        .nav-link {
          border-bottom: solid 1px $gray-lighter;
        }
      }
    }

    .menu-toggle {
      display: block;
      height: $nav-mobile-height;
      border: $nav-mobile-border;
      border-radius: $border-radius 0 0 $border-radius;
      color: $brand-primary;

      &:hover,
      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none;
        background: #fff;
      }

      &.active {
        background-color: #fff;
      }

      &.active + .nav {
        display: flex;
      }

      &.non-active {
        background-color: $brand-primary;
        color: #fff;
        border-radius: $border-radius 0 0 0;
      }
    }

    &.fixed-menu {
      position: fixed;
      top: 0;
      left: 0 !important;
      right: $nav-mobile-btn-right-width;
      width: auto !important;

      .menu-toggle {
        border-radius: 0;
      }
    }
  }
}

.nav-tabs {
  .nav-item {
    .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      color: #464a4c;
      height: 100%;
      background-color: #fff;
      border-color: #ddd #ddd #fff;
      border-radius: $border-radius*2 $border-radius*2 0 0;

      @include media-breakpoint-down(md) {
        padding: .5rem;
      }
      &.active {
        color: #fff;
        background-color: #8E8E8E;
      }
    }
  }
}