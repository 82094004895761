.btn-primary {
  @include hover {
    background-color: $button-primary-bg;

    &:active,
    &.active,
    .show > &.dropdown-toggle {
      background-color: $button-primary-bg;
    }
  }
}

button:focus {
  outline: 0 !important;
}

.btn {
  white-space: normal;
}

@keyframes blink-btn {
  50% {
    opacity: 0.3;
  }
}

.btn-infos-immeuble {
  @extend .btn;
  @extend .btn-outline-primary;

  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  .fa {
    font-size: 1.5rem;
    margin-right: 0.5rem;

    animation: blink-btn ease-in 1.5s infinite;
  }

  .text {
    line-height: 1.5rem;
  }
}

.btn-grey {
  @extend .btn-secondary;

  background-color: #C0C0C0;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #a7a7a7;
    color: #fff;
  }
}

button {
  cursor: pointer;
}

.btn-personalized {
  @include button-variant($white, $brand-primary, $brand-primary);
}

.btn-personalized-o {
  @include button-outline-variant($brand-primary);
}