.banner {
  .responsive-notice-fixed {
    @extend .responsive-notice;
    position: absolute;
    top: -1rem;
    right: 0;
  }

  .current-account {
    margin-top: -1rem;
    margin-bottom: -1rem;

    padding-left: 100px;

    @include media-breakpoint-down(sm) {
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      margin: 0;
      font-size: 1rem;
    }
  }
}

.page-content {

  position: relative;

  .content.avec-acces-rapide {
    @include make-col-ready();
    @include make-col(12 - $acces-rapide-superposition);

    padding: 0;

    @include media-breakpoint-down(md) {
      @include make-col(12);
      border: none;
    }

    .content-inner {
      @include make-col-ready();
      @include make-col(12 - $acces-rapide-col + $acces-rapide-superposition);

      padding-right: $grid-gutter-width-base !important;
      padding-bottom: $grid-gutter-width-base;
      margin-top: 3rem;
      min-height: 500px;

      @include media-breakpoint-down(md) {
        @include make-col(12);
        padding-right: $grid-gutter-width-base/2 !important;

        margin-top: $nav-mobile-margin-top + $nav-mobile-height + $nav-mobile-margin-bottom;

        min-height: 0;
      }
    }
  }

  #acces-rapide {
    @include make-col-ready();
    @include make-col($acces-rapide-col);

    display: flex;
    flex-direction: column;

    position: absolute;
    right: 0;
    top: 5rem;

    padding: 0 !important;

    z-index: 5;

    &.fixed-menu {
      position: fixed;
      top: 0;
    }

    .menu-toggle {
      display: none;
      align-self: flex-end;
    }

    .acces-rapide-box {

      background: #fff;

      padding: 1rem 0.8rem;
      border: $green-oralia 1px solid;
      border-radius: $border-radius;

      box-shadow: 0 0 1rem -.5rem;

      .list-item {
        list-style: none;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        font-size: 0.84rem;

        li {
          transition: all 0.2s;

          &.profil {
            background: url('../images/profile.png') left center no-repeat;
          }
          &.grouper-biens {
            background: url('../images/home-add.png') left center no-repeat;
          }
          &.contact {
            background: url('../images/phone.png') left center no-repeat;
          }
          &.tools {
            background: url('../images/tools.png') left center no-repeat;
          }
          &.ville {
            background: url('../images/city.png') left center no-repeat;
          }
          &.signaler {
            background: url('../images/bell.png') left center no-repeat;
          }
          &.question {
            background: url('../images/question.png') left center no-repeat;
          }          
          &.epaiement {
            background: url('../images/epaiement.png') left center no-repeat;
          }
          &.convoc {
            background: url('../images/email.png') left center no-repeat;
          }
          &.services {
            background: url('../images/ring.png') left center no-repeat;
          }
          &.vente {
            background: url('../images/sell-house.png') left center no-repeat;
          }
		  &.vendre-gerer {
            background: url('../images/vendregerer.png') left center no-repeat;
          }
		  &.joindre {
            background: url('../images/joindre.png') left center no-repeat;
          }
		  &.idee {
            background: url('../images/idee.png') left center no-repeat;
          }
		  &.soutien {
            background: url('../images/soutien.png') left center no-repeat;
          }

          background-size: 26px !important;

          &:hover,
          &:active,
          &.active {
            background-size: 30px !important;
            background-color: $gray-lightest;
            background-position: 0.8rem center;

            margin-right: -0.8rem;
            margin-left: -0.8rem;
            padding-left: 0.8rem;
            padding-right: 0.8rem;

            a {
              color: $brand-primary;
              font-weight: 500;

            }

          }

          a {
            display: block;
            padding-left: 42px;
            padding-top: 0.65rem;
            padding-bottom: 0.65rem;
            color: #000;

            &:hover {
              text-decoration: none;
            }

            small {
              text-transform: none;
              font-size: 0.85em;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      @include make-col(12);

      top: $nav-mobile-margin-top;
      right: $nav-mobile-margin-side;
      left: $nav-mobile-margin-side;

      max-height: 100%;
      width: auto !important;

      &.fixed-menu {
        right: 0 !important;
        left: 0 !important;

        .menu-toggle {
          border-radius: 0;
        }
      }

      .menu-toggle {
        display: inline-block;
        width: $nav-mobile-btn-right-width;
        height: $nav-mobile-height;
        border: $nav-mobile-border;
        border-radius: 0 $border-radius $border-radius 0;

        &:hover,
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
          background-color: $brand-primary;
        }

        &.active {
          color: $brand-primary;
          background-color: #fff;
          border-left: none;
        }

        &.active + .acces-rapide-box {
          display: block;
        }

        i {
          font-size: 1.8rem;
        }
      }

      .acces-rapide-box {
        display: none;
        max-height: 100%;
        margin-bottom: 1.5rem;
        overflow-y: auto;
        border: $nav-mobile-border;
        border-top: none;
        border-radius: 0 0 $border-radius $border-radius;
        margin-top: -$nav-mobile-border-size;
        box-shadow: none;
      }
    }

  }
}