.convocation-electronique {
  .download-convoc {
    display: block;
    color: #000;
    text-decoration: none !important;
    .card {
      transition: background-color 0.25s;
      &:hover {
        background-color: $color-copropriete;
        color: #fff;

        .btn {
          border-radius: 0;
          &:hover,
          &:focus {
            background-color: $color-copropriete;
            border-color: $color-copropriete;
          }
        }
      }

      .card-block {
        border-bottom: 1px solid #fff;
      }
    }
  }
}

.card.card-voisinage {
  .card-block {

    .card-title {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: $brand-primary;
      margin: 0 0 1.5rem 0;

      .fa {
        color: $black;
        margin-right: .8rem;
        font-size: 1.7rem;
      }
    }

    .card-subtitle {
      margin: 1rem 0;
    }

    .card-text {
      font-style: italic;
    }

    .btn-personalized,
    .btn-personalized-o {
      padding: .1em .4em;
      font-size: 1.5rem;
    }

    .author-info {
      display: flex;
      align-items: center;
      font-weight: bold;
      img {
        max-height: 3rem;
        margin-right: .5rem;
      }
    }
  }

  &:hover {
    box-shadow: inset 0 2px 2px rgba($white, .15), 0 0 8px rgba($black, .075);
  }
}

.nos-services {
  .justify-content-center{
    -webkit-box-pack: left!important;
    -ms-flex-pack: left!important;
    justify-content: left!important;
  }
}


.card.service {
  height: 440px;
  font-size: .95rem;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
  &:hover {
    border-color: $brand-primary;
  }
  .card-block {
    display: flex;
    flex-direction: column;
  }
  .btn {
    align-self: center;
    min-width: 70%;
    margin-top: auto;
  }
  .card-img {
    display: flex;
    align-items: center;
    max-width: 16rem;
    margin-right: auto;
    margin-left: auto;

    height: 5rem;
    @include media-breakpoint-up(sm) {
      height: 7rem;
    }

    img {
      margin-left: auto;
      margin-right: auto;
      max-height: 100%;
    }
  }
  .card-title {
    margin-top: 2rem;
    font-size: 1.4em;
  }
}

.card-outline-transaction {
  @extend .card-outline-primary;

  border-color: $rose-oralia-transaction;
}