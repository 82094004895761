.connexion,
.mot-de-passe-oublie,
.regrouper-biens {

  .page-content {
    @extend .justify-content-center;
  }

  .connexion-box {
    @include make-col-ready();

    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(10);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(7);
    }

    @extend .text-center;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 3rem;

      @include media-breakpoint-down(xs) {
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.connexion-box-content {
  @include make-row();
  @extend .justify-content-center;

  form {
    @include make-col-ready();

    @include make-col(10);

    @include media-breakpoint-down(xs) {
      @include make-col(12);
    }

    input, button {
      margin-bottom: 1.5rem;
      padding: 1rem;
      text-align: center;
    }

    button {
      img {
        margin-right: .8rem;
        margin-top: -.2rem;
        height: 1.5rem;
      }
    }

    .form-check {
      text-align: left;
    }
  }
}