/* COLOR */
$green-oralia: #006139;
$blue-oralia: #03a7df;
$green-light-oralia: #87bb34;
$rose-oralia-transaction: #e94974;
$orange-oralia: #f2951e;

$brand-primary: $green-oralia;
$input-border-focus: $green-oralia;

$button-primary-bg: #014a2c;

$color-copropriete: $blue-oralia;
$color-location: $orange-oralia;

$brand-info: #01AAEB;
$brand-warning: #F26431;

/* HEADERS */
$headings-font-weight: 600;

/* OTHER */
$chevauchement-banner: 3rem;
$banner-padding-y: 2rem;
$page-header-margin-bottom: 4rem;

/* TEMPLATE */
$acces-rapide-col: 3;
$acces-rapide-superposition: 1;

/* NAV */
$nav-mobile-height: 3rem;
$nav-mobile-border-size: 2px;
$nav-mobile-border: $nav-mobile-border-size solid $brand-primary;
$nav-mobile-margin-top: 1rem;
$nav-mobile-margin-bottom: 2rem;
$nav-mobile-margin-side: 0.8rem;
$nav-mobile-btn-right-width: 6rem;

/* MON VOISINAGE */
$voisinage-picto-size: 30px;

/* FIL D'ACTUALITE */
$publication-bg-color: #fff;
$publication-border-color: #a7a7a7;
$publication-triangle-size: 10px;

/* TIMELINE */
$timeline-border-color: #8F8F8F;
