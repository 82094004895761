.timeline {
  border-left: 4px solid $timeline-border-color;

  .date-point {
    display: inline-block;
    color: #fff;
    background-color: $timeline-border-color;
    padding: .5rem 1rem;
    margin-top: .5rem;
    margin-bottom: .5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .event-point {
    @extend .row;
    align-items: center;

    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: -1.25rem;

    .event-icon {
      position: relative;

      @extend .col;
      @extend .col-auto;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0;
      font-size: 1.5rem;
      background-color: $brand-primary;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      color: #fff;

    }
    .event-box {
      @extend .col;

      .event-content {
        border: 1px solid $timeline-border-color;
        border-radius: $border-radius;
        padding: .5rem 1rem;
      }
    }

    &.start-point {
      margin-top: 2rem;
      .event-icon {
        background-color: $timeline-border-color !important;
      }
    }
  }
}