.form-publication {
  @extend .container-fluid;
  border: 1px solid #a7a7a7;
  border-radius: $border-radius;

  img {
    margin: .5rem 0 .5rem .5rem;
  }

  textarea {
    border: none;
    height: 100%;
    padding: 1rem .5rem 1rem 0;
    min-height: 5.5rem;
  }

  .row:last-child {
    border-top: 1px solid #dadada;
  }
}

.actualite-publication {
  margin-bottom: 2rem;
  .message-profile {
    width: 2.5rem;
    margin-right: -$publication-triangle-size;
    margin-left: -($grid-gutter-width-base/3);
    @include media-breakpoint-up(sm) {
      width: 3.5rem;
    }
  }
  .message-author {
    font-size: .9em;
    font-weight: bold;
    margin-bottom: .3rem;
  }
  .message-content {
    position: relative;
    padding: .5rem 1rem 0 1rem;
    border-radius: 0 .5rem .5rem .5rem;
    font-size: .95rem;
    border: 1px solid $publication-border-color;
    background-color: $publication-bg-color;
    margin-top: 1rem;

    &::before,
    &::after {
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      content: '';
      margin-left: -$publication-triangle-size;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 $publication-triangle-size $publication-triangle-size 0;
      border-color: transparent $publication-border-color transparent transparent;
      z-index: 1;
    }

    &::after {
      border-color: transparent $publication-bg-color transparent transparent;
      z-index: 2;
      border-width: 0 ($publication-triangle-size - 1px) ($publication-triangle-size - 1px) 0;
      top: 0;
      margin-left: -($publication-triangle-size - 2px);
    }

    time {
      position: absolute;
      top: .5rem;
      right: 1rem;
      font-size: .75rem;
      color: $publication-border-color;
    }

    .message-meta {
      margin-left: -$grid-gutter-width-base/2;
      margin-right: -$grid-gutter-width-base/2;
      margin-top: .5rem;
      border-top: 1px solid #dadada;
      text-align: right;

      .btn {
        padding: .5rem .5rem;
        color: $publication-border-color;
        text-decoration: none;

        &:hover {
          .fa-heart {
            color: $red;
          }
          .fa-comment {
            color: $brand-primary;
          }
        }
      }
    }

    .message-comments {
      margin-left: -$grid-gutter-width-base/2;
      margin-right: -$grid-gutter-width-base/2;

      background-color: #f3f3f3;
      border-radius: 0 0 .5rem .5rem;

      ul.list-comments {
        margin-bottom: 0;
        list-style: none !important;
        padding-left: $grid-gutter-width-base/2;
        padding-right: $grid-gutter-width-base/2;
        li {
          padding: .5rem 0;
          time {
            top: 0;
          }
          .message-text p:last-child {
            margin-bottom: 0;
          }
          .message-meta {
            margin-left: -.4rem;
            margin-right: 0;
            border-top: none;
            text-align: left;
            margin-top: 0;
            font-size: .8em;

            .btn {
              padding: .3rem .4rem;
            }
          }
        }
      }

      .comment-form {
        width: 100%;
        background-color: #fff;
        border-radius: 0 0 .5rem .5rem;
        border-top: 1px #a7a7a7 solid;

        .form-control {
          background: none;
          border: none;
          height: 100%;
          padding-top: .8rem;
          padding-bottom: .8rem;
        }

        .file-input-label {
          cursor: pointer;
          .fa {
            color: #bebdbd;
          }
          &:hover .fa {
            color: #333;
          }
        }

      }
    }
  }
}