h2 {
  color: $brand-primary;
  text-align: center;
  font-size: 1.5rem;
}

.page-content {
  h1 {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 0;
    font-weight: bolder;
    border-bottom: 2px solid $gray-lighter;
    margin-bottom: 3rem;
    color: $brand-primary;

    .little-border {
      display: inline-block;
      padding-bottom: 1rem;
      border-bottom: 2px solid $green-oralia;
      margin-bottom: -2px;

      &.color-transaction {
        border-color: $rose-oralia-transaction !important;
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  h4 {
    color: $brand-primary;
    margin-top: 1.5em;
    margin-bottom: 1rem;
  }

  h5 {
    font-weight: normal;
  }
}
.title-modal-message {
  color: #000;
  text-transform: uppercase;
}